import { Link } from 'react-router-dom';
import Layout from '../components/layouts';
import items from '../data/fellows';

type Fellow = {
  id: string;
  type: string;
};

const fellows: Fellow[] = items.map((f, i) => ({
  id: f.id,
  type: f.type,
}));

const backgroundStyle = {
  backgroundImage: 'url(/img/bg.jpg)',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const Fellows = () => {
  return (
    <Layout>
      <div
        className="flex flex-col items-center px-12 pt-12 pb-16"
        style={backgroundStyle}
      >
        <div className="grid grid-cols-2 gap-x-8 gap-y-12">
          {fellows.map((fellow: Fellow) => (
            <Link
              to={`/fellows/${fellow.id}`}
              key={fellow.id}
              className="flex flex-col items-center space-y-5"
            >
              <img src={`/img/${fellow.id}.png`} alt={fellow.type} />
              <div className="text-sm text-purple-900 font-nanum font-bold">
                {fellow.type}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Fellows;
