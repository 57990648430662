import React, { FunctionComponent } from 'react';

type Props = {
  fullWidth?: boolean;
  children: React.ReactNode;
};

const Public: FunctionComponent<Props> = ({ fullWidth, children }) => {
  const mainClass = fullWidth ? '' : 'max-w-xl md:mx-auto';

  return <main className={mainClass}>{children}</main>;
};

export default Public;
