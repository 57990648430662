import { useEffect, useMemo, useState } from 'react';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import ForceGraph3D, { GraphData } from 'react-force-graph-3d';
import firebase from 'firebase/app';
import { firestore } from '../lib/firebase';
import fellows from '../data/fellows';

const initialData = {
  nodes: [{ id: 0, name: '', group: 0 }],
  links: [],
};

type Node = {
  id: string;
  name: string;
  group: number;
  size?: number;
};

type Link = {
  source: string;
  target: string;
  value: number;
};

let generated: boolean = false;

const items: Node[] = fellows.map((f, i) => ({
  id: f.id,
  name: f.name,
  group: i + 1,
  size: 6,
}));

const colors: string[] = fellows.map((f) => f.color);
colors.unshift('#000000');
colors.push('#0096FF');

let parents: { [key: string]: string[] } = {};

const getParent = (parents: string[]): string =>
  parents[getRandomInt(0, parents.length)];

const setParent = (fellow: string, id: string): void => {
  if (!parents[fellow]) parents[fellow] = [];
  if (parents[fellow].length === 0) {
    parents[fellow].push(fellow);
    return;
  }

  if (getRandomInt(0, 2) === 1) {
    if (parents[fellow].length > 10) parents[fellow].shift();
    parents[fellow].push(id);
  }
};

const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

const generateData = (
  logs: firebase.firestore.DocumentData[],
  uid?: string
) => {
  const nodes = [{ id: 'root', name: '', group: 0 }].concat(items);

  const links: Link[] = fellows.map((f) => ({
    source: 'root',
    target: f.id,
    value: 1,
  }));

  const newNodes: Node[] = [];
  const newLinks: Link[] = [];

  logs.forEach((doc: firebase.firestore.DocumentData) => {
    let group = nodes.find((x) => x.id === doc.fellowId)?.group || 0;
    const source = parents[doc.fellowId]?.length
      ? getParent(parents[doc.fellowId])
      : doc.fellowId;
    setParent(doc.fellowId, doc.id);

    if (uid && doc.uid === uid) group = 11;

    newNodes.push({ id: doc.id, name: doc.user.name, group });
    newLinks.push({ source, target: doc.id, value: 5 });
  });

  return { nodes: [...nodes, ...newNodes], links: [...links, ...newLinks] };
};

const ForceGraph = ({ uid }: { uid?: string }) => {
  const [data, setData] = useState<GraphData>(initialData);

  const query = useMemo(() => {
    return firestore.collection('logs');
  }, []);

  const [logs] = useCollectionDataOnce(query, { idField: 'id' });

  useEffect(() => {
    return () => {
      setData(initialData);
      generated = false;
      parents = {};
    };
  }, []);

  useEffect(() => {
    if (logs && !generated) {
      const { nodes, links } = generateData(logs, uid);
      setData({ nodes, links });
      generated = true;
    }
  }, [logs, uid]);

  return (
    <ForceGraph3D
      backgroundColor="#f1f1f1"
      graphData={data}
      nodeVal={(d: any) => (d.size || d.group === 11 ? 6 : 2)}
      nodeLabel={(d: any) => `<span style="color: #333333">${d.name}</span>`}
      nodeColor={(d: any) => colors[d.group]}
      linkColor={() => '#000000'}
      linkDirectionalParticles="value"
    />
  );
};

export default ForceGraph;
