import { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resizeImage } from '../utils/resizeImage';
import { UserContext } from '../lib/context';
import { auth, firestore, storage } from '../lib/firebase';
import Layout from '../components/layouts/default';
import AvatarModal from '../components/AvatarModal';

interface stateType {
  from: { pathname: string };
}

const defaultAvatar = '/img/avatar.png';

const Signup = () => {
  const [processing, setProcessing] = useState(false);
  const [form, setForm] = useState({ name: '' });
  const [image, setImage] = useState<string>('');
  const [avatar, setAvatar] = useState<string>(defaultAvatar);
  const [cropData, setCropData] = useState<string | null>(null);
  const { state } = useLocation<stateType>();
  const { user, info } = useContext(UserContext);

  useEffect(() => {
    if (cropData) {
      setAvatar(cropData);
    }
  }, [cropData]);

  useEffect(() => {
    return () => {
      setAvatar(defaultAvatar);
      setCropData(null);
    };
  }, []);

  if (user && info) {
    return <Redirect to={state.from || '/'} />;
  }

  const errors = { name: false };
  const { name } = form;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onSelect = async (selectorFiles: FileList | null) => {
    if (!selectorFiles) return;

    const file = selectorFiles[0];
    if (!file) return;
    // const image = await resizeImage(file);
    // setImage(image);

    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        setImage(reader.result);
      }
    };

    reader.readAsDataURL(file);
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (form.name.length < 2 || !cropData) return;

    setProcessing(true);
    try {
      const result = await auth.signInAnonymously();
      const uid = result.user?.uid;

      const base64Str = await resizeImage(cropData);
      const storageRef = storage.ref().child(`users/${uid}.jpg`);
      const snapshot = await storageRef.putString(base64Str, 'data_url', {
        contentType: 'image/jpeg',
      });
      const avatar = await snapshot.ref.getDownloadURL();
      await firestore.collection('users').doc(uid).set({
        name: form.name,
        avatar,
      });

      toast.success('프로필을 등록했습니다.');
    } catch (error) {
      toast.error(error?.message);
    }
    setProcessing(false);
  };

  return (
    <Layout>
      <form className="py-12" onSubmit={onSubmit}>
        <div className="flex flex-col items-center space-y-5 mb-4">
          <div className="relative w-32 h-32 rounded-full overflow-hidden">
            <img
              className="w-full h-full object-cover"
              src={info?.avatar || avatar}
              alt="아바타 이미지"
            />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => onSelect(e.target.files)}
              className="absolute w-32 inset-0 opacity-0 cursor-pointer"
            />
          </div>
          <div className="w-2/3">
            <label
              htmlFor="band_name"
              className="block text-sm font-medium text-gray-700"
            >
              닉네임
            </label>
            <input
              type="text"
              name="name"
              value={name}
              onChange={onChange}
              className={`${
                errors.name
                  ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                  : 'border-gray-300 focus:ring-purple-500 focus:border-purple-500'
              } mt-1 block w-full shadow-sm sm:text-sm rounded-md`}
            />
          </div>
          <button
            type="submit"
            className="w-2/3 py-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50"
            disabled={processing || form.name.length < 2 || !cropData}
          >
            {processing ? '저장 중입니다...' : '시작하기'}
          </button>
        </div>
        <div className="pt-6 px-4 md:px-0 text-gray-800">
          나는 누구와 닮았나?
          <br />
          살아가는 것이 곧 늙어가는 것이지요.
          <br />그 누구도 피해 가지 못하는 우리 모두의 나이듦과 고령화 이슈에
          접근하는 아쇼카 펠로우들이 있습니다. 그들은 질문을 통해 창의적인
          솔루션을 찾아갑니다.
        </div>
      </form>
      <AvatarModal
        setCropData={setCropData}
        image={image}
        setImage={setImage}
      />
    </Layout>
  );
};

export default Signup;
