import { useContext } from 'react';
import { UserContext } from '../lib/context';
import Layout from '../components/layouts';
import ForceGraph from '../components/ForceGraph';

const Friends = () => {
  const { user } = useContext(UserContext);

  return (
    <Layout fullWidth>
      <ForceGraph uid={user?.uid} />
    </Layout>
  );
};

export default Friends;
