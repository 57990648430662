import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from './firebase';
import { UserInfo } from '../types';

export function useUserData() {
  const [user, loading] = useAuthState(auth);
  const [info, setInfo] = useState<UserInfo | null>(null);

  useEffect(() => {
    // turn off realtime subscription
    let unsubscribe;

    if (user) {
      const ref = firestore.collection('users').doc(user.uid);
      unsubscribe = ref.onSnapshot((doc) => {
        const info = doc.data();
        if (info) {
          setInfo({
            id: doc.id,
            name: info.name,
            avatar: info.avatar,
            fellowId: info.fellowId,
            logId: info.logId,
          });
        }
      });
    } else {
      setInfo(null);
    }

    return unsubscribe;
  }, [user]);

  return { user, info, loading };
}

export interface Size {
  width: number | undefined;
  height: number | undefined;
}

export function useWindowSize(): Size {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
