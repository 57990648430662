import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { toast } from 'react-toastify';
import { auth, firestore, serverTimestamp, storage } from '../lib/firebase';
import { UserContext } from '../lib/context';
import { classNames } from '../utils';
import { resizeImage } from '../utils/resizeImage';
import Layout from '../components/layouts';
import Analyzing from '../components/Analyzing';
import AvatarModal from '../components/AvatarModal';
import items from '../data/fellows';

type Fellow = {
  id: string;
  name: string;
  point: number;
};

const defaultAvatar = '/img/avatar.png';
const bgStyle = { backgroundColor: '#f1f1f1' };

const countSelected = (array: string[]): number => array.length;
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const Start = () => {
  const history = useHistory();
  const { user, info } = useContext(UserContext);

  const [processing, setProcessing] = useState<boolean>(false);
  const [image, setImage] = useState<string>('');
  const [cropData, setCropData] = useState<string | null>(null);
  const [avatar, setAvatar] = useState<string | null>(null);
  const [inputs, setInputs] = useState({ name: '' });
  const [selected, setSelected] = useState<string[]>([]);

  const query = useMemo(() => {
    return firestore.collection('questions');
  }, []);

  const count = useMemo(() => countSelected(selected), [selected]);
  const disabled = useMemo(
    () => count < 10 || !avatar || inputs.name.length < 2,
    [count, avatar, inputs]
  );

  const errors = { name: false };

  const [data] = useCollectionDataOnce(query, { idField: 'id' });

  useEffect(() => {
    return () => {
      setCropData(null);
      setSelected([]);
    };
  }, []);

  useEffect(() => {
    if (cropData) setAvatar(cropData);
  }, [cropData]);

  useEffect(() => {
    if (info) {
      setAvatar(info.avatar);
      setInputs({ name: info.name });
    }
    if (cropData) setAvatar(cropData);
  }, [info, cropData]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onSelect = (selectorFiles: FileList | null) => {
    if (!selectorFiles) return;

    const file = selectorFiles[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        setImage(reader.result);
      }
    };

    reader.readAsDataURL(file);
  };

  const onToggle = (id: string) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((x) => x !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const reset = () => setSelected([]);

  const updateUser = async (): Promise<string[]> => {
    if (!user || !info || !avatar || !inputs.name) return [];

    let avatarUrl;
    if (avatar.startsWith('data:image')) {
      const base64Str = await resizeImage(avatar);
      const fileName = Math.random().toString(36).substr(2, 8);
      const storageRef = storage
        .ref()
        .child(`users/${user.uid}-${fileName}.jpg`);
      const snapshot = await storageRef.putString(base64Str, 'data_url', {
        contentType: 'image/jpeg',
      });
      avatarUrl = await snapshot.ref.getDownloadURL();
    } else {
      avatarUrl = info.avatar;
    }

    await firestore.collection('users').doc(user.uid).update({
      name: inputs.name,
      avatar: avatarUrl,
    });

    return [user.uid, inputs.name, avatarUrl];
  };

  const createUser = async (): Promise<string[]> => {
    if (!avatar || !inputs.name) return [];

    const result = await auth.signInAnonymously();
    const uid = result.user?.uid;

    const base64Str = await resizeImage(avatar);
    const fileName = Math.random().toString(36).substr(2, 8);
    const storageRef = storage.ref().child(`users/${uid}-${fileName}.jpg`);
    const snapshot = await storageRef.putString(base64Str, 'data_url', {
      contentType: 'image/jpeg',
    });
    const avatarUrl = await snapshot.ref.getDownloadURL();

    await firestore.collection('users').doc(uid).set({
      name: inputs.name,
      avatar: avatarUrl,
      createdAt: serverTimestamp(),
    });

    return [uid, inputs.name, avatarUrl];
  };

  const analyze = () => {
    const fellows = items.map((f, i) => ({
      id: f.id,
      name: f.name,
      point: 0,
    }));

    selected.forEach((str) => {
      const question = data?.find((x) => x.id === str);
      const idx = fellows.findIndex((x) => x.id === question?.fellowId);
      fellows[idx].point += question?.point;
    });

    return fellows.reduce((prev: Fellow, current: Fellow) =>
      prev.point >= current.point ? prev : current
    );
  };

  const onSubmit = async () => {
    setProcessing(true);

    let uid, name, avatarUrl;
    try {
      [uid, name, avatarUrl] = user ? await updateUser() : await createUser();
      if (!uid || !name || !avatarUrl) throw new Error('문제가 발생했습니다!');

      await sleep(3000);

      const max = analyze();

      const logRef = await firestore.collection('logs').add({
        createdAt: serverTimestamp(),
        fellowId: max.id,
        points: max.point,
        uid: uid,
        user: {
          name,
          avatar: avatarUrl,
        },
        via: 'web',
      });

      await firestore.collection('users').doc(uid).update({
        fellowId: max.id,
        logId: logRef.id,
      });

      toast.success('분석을 완료했습니다.');
      history.push('/you');
    } catch (error: unknown) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Layout>
      {processing && <Analyzing />}
      <div className="flex flex-col items-center space-y-2 pt-8 pb-1">
        <div className="relative w-24 h-24 rounded-full overflow-hidden">
          <img
            className="w-full h-full object-cover"
            src={avatar || defaultAvatar}
            alt="아바타 이미지"
          />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => onSelect(e.target.files)}
            className="absolute w-24 inset-0 opacity-0 cursor-pointer"
          />
        </div>
        <input
          type="text"
          name="name"
          value={inputs.name}
          placeholder="닉네임"
          onChange={onChange}
          className={classNames(
            errors.name
              ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
              : 'border-gray-300 focus:ring-purple-500 focus:border-purple-500',
            'mt-1 block w-1/2 shadow-sm sm:text-sm rounded-md text-center'
          )}
        />
      </div>
      <div className="flex flex-col items-center space-y-2 px-4 mt-3">
        <h3 className="text-xl text-purple-800 font-semibold font-nanum">
          질문담기
        </h3>
        <div className="text-center text-gray-700 text-sm">
          다음 펠로우의 질문 중에서 마음에 와닿는 질문을 10개 이상 선택해
          주세요.
          <br />
          질문이 데리고 가는 그곳에서 여러분은 누구를 만나게 될까요?
        </div>
      </div>
      <div
        className="sticky top-0 z-10 flex flex-col items-center space-y-1 py-3"
        style={bgStyle}
      >
        {avatar && inputs.name ? (
          <div className="text-4xl text-gray-800 font-semibold">{count}</div>
        ) : (
          <div className="text-sm text-red-400 font-semibold py-2">
            프로필 사진과 닉네임을 먼저 설정해 주세요!
          </div>
        )}
        <div className="space-x-2 font-nanum">
          <button
            type="button"
            onClick={onSubmit}
            className="text-center px-12 py-1.5 bozrder border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={disabled || processing}
          >
            유형분석
          </button>
          <button
            type="button"
            onClick={reset}
            className="text-center px-4 py-1.5 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={count < 1}
          >
            전체취소
          </button>
        </div>
      </div>
      <div className="pb-8">
        {data && (
          <ul className="space-y-2 mx-3 sm:mx-0">
            {data.map((item) => (
              <li
                key={item.id}
                className="relative rounded-lg border border-gray-300"
              >
                <div
                  onClick={() => onToggle(item.id)}
                  className={classNames(
                    selected.includes(item.id)
                      ? 'border-indigo-800'
                      : 'border-transparent',
                    'border-2 rounded-lg bg-white shadow-sm px-6 py-4 cursor-pointer'
                  )}
                >
                  <span className="text-gray-900 text-sm">{item.question}</span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      <AvatarModal
        setCropData={setCropData}
        image={image}
        setImage={setImage}
      />
    </Layout>
  );
};

export default Start;
