import { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { firestore } from '../lib/firebase';
import Layout from '../components/layouts';
import LoadingSpinner from '../components/LoadingSpinner';
import FellowDetail from '../components/FellowDetail';
import OtherTypesButton from '../components/OtherTypesButton';

interface MatchParams {
  id: string;
}

const Fellow = ({ match }: RouteComponentProps<MatchParams>) => {
  const { id } = match.params;

  const query = useMemo(() => {
    return firestore.collection('fellows').doc(id);
  }, [id]);

  const [fellow, loading, error] = useDocumentDataOnce(query, {
    idField: 'id',
  });

  return (
    <Layout>
      {loading && <LoadingSpinner />}
      {error && <p>{error?.message}</p>}
      {fellow && (
        <>
          <FellowDetail fid={fellow.id} fellow={fellow} user={null} back />
          <OtherTypesButton />
        </>
      )}
    </Layout>
  );
};

export default Fellow;
