import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { UserContext } from './lib/context';
import { useUserData } from './lib/hooks';
import { ScrollToTop } from './utils';
import ProtectedRoute from './components/ProtectedRoute';
import Signup from './pages/signup';
import Signout from './pages/signout';
import Home from './pages/home';
import Start from './pages/start';
import You from './pages/you';
import Circle from './pages/circle';
import Friends from './pages/friends';
import Fellows from './pages/fellows';
import Fellow from './pages/fellow';
import Result from './pages/result';
import NotFound from './pages/notfound';
import Welcome from './pages/welcome';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

const Init = () => {
  return (
    <div className="h-screen flex items-center justify-center">
      <img src="/favicon.png" alt="Initializing..." className="w-12 h-auto" />
    </div>
  );
};

const App = () => {
  let { user, info, loading } = useUserData();

  if (loading) {
    return <Init />;
  }

  return (
    <UserContext.Provider value={{ user, info }}>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/signup" component={Signup} />
          <Route path="/signout" component={Signout} />
          <Route path="/start" component={Start} />
          <ProtectedRoute path="/you" component={You} />
          <Route path="/circle" component={Circle} />
          <Route path="/friends" component={Friends} />
          <Route path="/result/:id" component={Result} />
          <Route path="/fellows/:id" component={Fellow} />
          <Route path="/fellows" component={Fellows} />
          <Route path="/welcome" component={Welcome} />
          <Route exact path="*" component={NotFound} />
        </Switch>
        <ToastContainer hideProgressBar newestOnTop />
      </Router>
    </UserContext.Provider>
  );
};

export default App;
