import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../lib/context';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
  path: string;
  exact?: boolean;
}

export default function ProtectedRoute({
  component: Component,
  path,
  ...rest
}: Props) {
  const { info } = useContext(UserContext);

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return info && info.logId ? (
          <Component {...props} />
        ) : (
          <Redirect to="/start" />
        );
      }}
    />
  );
}
