import { NavLink } from 'react-router-dom';
import Headroom from 'react-headroom';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type Props = {
  fullWidth?: boolean;
  home?: boolean;
  children: React.ReactNode;
};

const videoURL =
  'https://firebasestorage.googleapis.com/v0/b/ashoka-1643b.appspot.com/o/bg-video.mp4?alt=media&token=947663cf-3052-4184-b0a6-875ebf58a22e';

const NewLayout = ({ fullWidth, home = false, children }: Props) => {
  const mainTop = home ? ' md:pt-10' : ' pt-10';
  const mainClass = fullWidth ? '' : `max-w-xl md:mx-auto${mainTop}`;

  return (
    <div className="h-screen relative">
      {home ? (
        <div className="hidden md:block">
          <video id="bgVideo" src={videoURL} autoPlay muted loop>
            <source src={videoURL} type="video/mp4" />
          </video>
        </div>
      ) : (
        <Headroom wrapperStyle={{ position: 'fixed', top: 0, zIndex: 10 }}>
          <div className="flex items-center h-10 px-4">
            <NavLink
              exact
              to={'/'}
              className={classNames(
                home ? 'text-white' : 'text-gray-600',
                'navlink flex items-center space-x-1'
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
              <span className="text-sm tracking-tighter font-nanum">
                나와 닮은 펠로우 유형 찾기
              </span>
            </NavLink>
          </div>
        </Headroom>
      )}

      <main className={mainClass}>{children}</main>
    </div>
  );
};

export default NewLayout;
