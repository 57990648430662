export type Fellow = {
  id: string;
  name: string;
  type: string;
  color: string;
};

const fellows: Array<Fellow> = [
  {
    id: 'anne-basting',
    name: '앤 베이스팅',
    type: '창의력을 끌어내는 연출가',
    color: '#F277C6',
  },
  {
    id: 'beka-ntsanwisi',
    name: '베카 네탄위시',
    type: '흥 넘치는 스트라이커',
    color: '#E93CAC',
  },
  {
    id: 'deagon-yi',
    name: '이대건',
    type: '이야기의 마법사',
    color: '#D7B9E4',
  },
  {
    id: 'lennon-flowers',
    name: '레논 플라워즈',
    type: '상처입은 치유자',
    color: '#C98BDB',
  },
  {
    id: 'ellen-goodman',
    name: '앨런 굿맨',
    type: '열정적인 소통가',
    color: '#F277C6',
  },
  {
    id: 'kusumadewi-suharya',
    name: '쿠스마데위 수하리아',
    type: '야무진 건강지킴이',
    color: '#87189D',
  },
  {
    id: 'marc-freedman',
    name: '마크 프리드먼',
    type: '통찰력있는 혁신가',
    color: '#642667',
  },
  {
    id: 'sergio-serapiao',
    name: '세르지오 세르피야오',
    type: '기발한 기획자',
    color: '#9B26B6',
  },
  {
    id: 'ole-kassow',
    name: '올레 카소우',
    type: '바람을 가르는 해결사',
    color: '#F4A6D7',
  },
  {
    id: 'katherine-freund',
    name: '캐서린 프로인드',
    type: '사려깊은 베스트 드라이버',
    color: '#C6007E',
  },
];

export default fellows;
