import { useEffect, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Modal from './Modal';

type Props = {
  image: string;
  setImage: (image: string) => void;
  setCropData: (cropped: string) => void;
};

const AvatarModal = ({ setCropData, image, setImage }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [cropper, setCropper] = useState<Cropper>();

  useEffect(() => {
    setOpen(!!image);
  }, [image]);

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      const cropped = cropper.getCroppedCanvas().toDataURL();
      setCropData(cropped);
      setImage('');
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
        <div>
          <Cropper
            style={{ height: 400, width: '100%' }}
            zoomTo={2}
            aspectRatio={1}
            initialAspectRatio={0.5}
            preview=".img-preview"
            src={image}
            viewMode={1}
            guides={true}
            // dragMode={true}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
              setCropper(instance);
            }}
          />
          <div className="text-center text-gray-800">
            <small>마우스 휠로 이미지를 확대/축소 할 수 있습니다.</small>
          </div>
        </div>
        <div className="mt-2">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:text-sm"
            onClick={getCropData}
          >
            자르기
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AvatarModal;
