import { Link } from 'react-router-dom';

type Props = {
  blank?: boolean;
  again?: boolean;
};

const OtherTypesButton = ({ blank, again }: Props) => {
  const buttonClass =
    'block w-2/3 text-center py-2 border border-transparent text-base font-nanum font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-50';
  const buttonText = '다른 유형 보러 가기';

  return (
    <div className="flex flex-col items-center justify-center space-y-2 h-24 mb-24">
      {blank && (
        <a
          href="/fellows"
          target="_blank"
          rel="noreferrer"
          className={buttonClass}
        >
          {buttonText}
        </a>
      )}
      {!blank && (
        <Link to={'/fellows'} className={buttonClass}>
          {buttonText}
        </Link>
      )}
      {again && (
        <Link
          to={'/start'}
          className="block w-2/3 text-center py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          다시하기
        </Link>
      )}
    </div>
  );
};

export default OtherTypesButton;
