import { useContext, useEffect, useMemo, useState } from 'react';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import firebase from 'firebase/app';
import { UserContext } from '../lib/context';
import { firestore } from '../lib/firebase';
import Layout from '../components/layouts';
import LoadingSpinner from '../components/LoadingSpinner';
import FellowDetail from '../components/FellowDetail';
import OtherTypesButton from '../components/OtherTypesButton';

type User = {
  name: string;
  avatar: string;
};

const You = () => {
  const { info } = useContext(UserContext);

  const [user, setUser] = useState<User | null>(null);
  const [fid, setFid] = useState<string>('');
  const [fellow, setFellow] = useState<
    firebase.firestore.DocumentData | null | undefined
  >(null);

  const query = useMemo(() => {
    return firestore.collection('logs').doc(info?.logId);
  }, [info]);

  const [data, , loading] = useDocumentDataOnce(query, { idField: 'id' });

  useEffect(() => {
    if (data) {
      const fetchAndSetData = async () => {
        try {
          const logSnap = await firestore.collection('logs').doc(data.id).get();
          const log = logSnap.data();

          const fellowSnap = await firestore
            .collection('fellows')
            .doc(log?.fellowId)
            .get();

          setFid(fellowSnap.id);
          setFellow(fellowSnap.data());
          setUser(log?.user);
        } catch (error) {}
      };

      fetchAndSetData();
    }
  }, [data]);

  return (
    <Layout>
      {loading && <LoadingSpinner />}
      {data && fellow && (
        <>
          <FellowDetail fid={fid} fellow={fellow} user={user} />
          <OtherTypesButton again />
        </>
      )}
    </Layout>
  );
};

export default You;
