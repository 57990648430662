import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../lib/context';
import Layout from '../components/layouts';
import items from '../data/fellows';

const fellows = items.map((f, i) => ({
  id: f.id,
  type: f.type,
}));

const Home = () => {
  const { info } = useContext(UserContext);

  return (
    <Layout home={true}>
      <div className="bg-white md:rounded-xl shadow-md p-6 md:p-10 md:pt-8">
        <div className="grid grid-cols-3 gap-1 md:gap-6">
          <div className="col-span-2 flex items-center justify-center">
            <img
              src="/img/home-title.jpg"
              alt="나는 누구와 닮았나?"
              className="inline w-44 md:w-56"
            />
          </div>
          {fellows.map((fellow) => (
            <div
              key={fellow.id}
              className="flex flex-col items-center space-y-2"
            >
              <img src={`/img/${fellow.id}.png`} alt={fellow.type} />
              <div className="text-xs md:text-sm font-nanum">{fellow.type}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="px-4 md:px-20 py-4 md:pt-6 md:pb-12 space-y-2 font-nanum">
        <NavLink
          to={'/start'}
          className="block w-full text-center py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
        >
          시작하기
        </NavLink>
        {info && info.logId && (
          <NavLink
            to={'/you'}
            className="block w-full text-center py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            최근 결과 보기
          </NavLink>
        )}
        <NavLink
          to={'/fellows'}
          className="block w-full text-center py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          모든 유형 보기
        </NavLink>
        <NavLink
          to={'/friends'}
          className="block w-full text-center py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          친구들
        </NavLink>
      </div>
    </Layout>
  );
};

export default Home;
