const Analyzing = () => {
  return (
    <div className="z-20 fixed inset-0 bg-black bg-opacity-75">
      <div className="h-screen flex flex-col items-center justify-center">
        <img
          src="/img/process.png"
          alt="분석중입니다"
          className="w-20 h-auto"
        />
        <span className="mt-8 text-white text-2xl">유형 분석중입니다.</span>
      </div>
    </div>
  );
};

export default Analyzing;
