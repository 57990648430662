import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import firebase from 'firebase/app';

type Props = {
  fid: string | undefined;
  fellow: firebase.firestore.DocumentData;
  user: {
    name: string;
    avatar: string;
  } | null;
  back?: boolean;
};

const backgroundStyle = {
  backgroundImage: 'url(/img/bg.jpg)',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};
const borderStyle = {
  borderColor: '#743e77',
};
const fontStyle = {
  color: '#743e77',
};
const quotesTop = {
  backgroundImage: 'url(/img/quotes1.png)',
  backgroundSize: '60px',
  backgroundRepeat: 'no-repeat',
};
const quotesBottom = {
  backgroundImage: 'url(/img/quotes2.png)',
  backgroundSize: '60px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right bottom',
};

const FellowDetail = ({ fid, fellow, user, back }: Props) => {
  const nameKo = fellow.name.ko;
  const nameEn = fellow.name.en;
  const nationalityKo = fellow.nationality.ko;
  const nationalityEn = fellow.nationality.en;
  const activityEn = fellow.activity.en;

  const title = user
    ? `${user.name}님과 ${nameKo}님은 ${fellow?.type} 유형`
    : fellow.type;

  return (
    <>
      <div className="" style={backgroundStyle}>
        {back && (
          <Link
            to={'/fellows'}
            className="inline-flex items-center justify-center m-1 p-3 text-gray-700 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
          >
            <ArrowLeftIcon className="block h-6 w-6" aria-hidden="true" />
          </Link>
        )}
        <div className="flex flex-col items-center">
          <div
            className="px-8 mt-20 text-center text-3xl font-nanum font-semibold"
            style={fontStyle}
          >
            {title}
          </div>
          <img src={`/img/${fid}.png`} alt={fellow.type} className="mt-12" />

          <div className="mx-8 my-10 md:my-16" style={quotesTop}>
            <div style={quotesBottom}>
              <div className="leading-8 text-xl md:text-2xl text-gray-500 font-nanum px-2 pt-14 pb-6">
                {fellow.story}
              </div>
            </div>
          </div>

          {user && (
            <div className="mb-12 flex items-center justify-center space-x-4">
              <img
                src={user.avatar}
                alt={user.name}
                className="inline-block h-20 w-20 rounded-full border-4"
                style={borderStyle}
              />
              <img src="/img/equal.png" alt="equal" className="w-8 h-auto" />
              <img
                src={`/img/${fid}.jpg`}
                alt={nameKo}
                className="inline-block h-20 w-20 rounded-full border-4"
                style={borderStyle}
              />
            </div>
          )}
        </div>
      </div>

      <img src={`/img/${fid}-A.jpg`} alt="대표사진" className="w-full" />

      <div className="px-8 py-12">
        <div className="text-2xl font-nanum font-semibold">
          {nameKo}, {nationalityKo} 펠로우
        </div>
        <div className="text-gray-500">
          {nameEn}, {nationalityEn} Fellow
        </div>
        <p className="mt-6 mb-2 text-lg text-gray-800 font-semibold">
          {activityEn}
        </p>
        <p className="mb-6 text-gray-600">{fellow?.description}</p>
      </div>

      {fellow.links && fellow.links.length && (
        <div className="pt-6 pb-24 w-full flex items-center justify-evenly">
          {fellow?.links.map((link: { type: string; url: string }) => (
            <a key={link.type} href={link.url} target="_blank" rel="noreferrer">
              <img
                src={`/img/icon-${link.type}.png`}
                alt={link.type}
                className="w-12 h-auto"
              />
            </a>
          ))}
        </div>
      )}
    </>
  );
};

export default FellowDetail;
