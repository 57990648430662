import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import firebase from 'firebase/app';
import { firestore } from '../lib/firebase';
import LoadingSpinner from '../components/LoadingSpinner';
import Layout from '../components/layouts/public';
import FellowDetail from '../components/FellowDetail';
import OtherTypesButton from '../components/OtherTypesButton';

interface MatchParams {
  id: string;
}

let blank = false;

const Result = ({ match }: RouteComponentProps<MatchParams>) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [log, setLog] = useState<
    firebase.firestore.DocumentData | null | undefined
  >(null);
  const [fellow, setFellow] = useState<
    firebase.firestore.DocumentData | null | undefined
  >(null);
  const [fid, setFid] = useState<string>('');

  const { id } = match.params;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('webview')) {
      blank = true;
    }
  }, []);

  useEffect(() => {
    async function fetchAndSetData() {
      setLoading(true);
      try {
        const logSnap = await firestore.collection('logs').doc(id).get();
        const log = logSnap.data();
        setLog(logSnap.data());
        setFid(log?.fellowId);

        const fellowSnap = await firestore
          .collection('fellows')
          .doc(log?.fellowId)
          .get();
        setFellow(fellowSnap.data());
      } catch (error) {}
      setLoading(false);
    }

    fetchAndSetData();
  }, [id]);

  return (
    <Layout>
      {loading && <LoadingSpinner />}
      {/* {error && <p>{error.message}</p>} */}
      {fellow && (
        <>
          <FellowDetail fid={fid} fellow={fellow} user={log?.user} />
          <OtherTypesButton blank={blank} />
        </>
      )}
    </Layout>
  );
};

export default Result;
