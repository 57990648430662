import { useEffect, useMemo, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { firestore } from '../lib/firebase';
import { Size, useWindowSize } from '../lib/hooks';
import fellows, { Fellow } from '../data/fellows';
import LoadingSpinner from '../components/LoadingSpinner';

const avatarStyle = (width: number) => {
  const w = 625 * (width / 1920);
  return {
    width: `${w}px`,
    height: `${w}px`,
    objectFit: 'cover',
  };
};

const wrapperStyle = (width: number, fellow: Fellow) => {
  return {
    width: `${1080 * (width / 1920)}px`,
    height: `${1920 * (width / 1920)}px`,
    color: fellow.color,
    fontSize: `${90 * (width / 1920)}px`,
    paddingTop: `${160 * (width / 1920)}px`,
    paddingBottom: `${110 * (width / 1920)}px`,
    backgroundImage: `url(/img/welcome/${fellow.id}.jpg)`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
  };
};

const Welcome = () => {
  const [offline, setOffline] = useState(false);
  const [wrapper, setWrapper] = useState({});
  const [avatar, setAvatar] = useState({});
  const [fellow, setFellow] = useState<Fellow | undefined>(undefined);

  const size: Size = useWindowSize();

  const query = useMemo(() => {
    return firestore.collection('appInfo').doc('monitor');
  }, []);

  const [data, loading, error] = useDocumentData(query);

  useEffect(() => {
    function log() {
      setOffline(!navigator.onLine);
    }
    window.addEventListener('offline', log);
    window.addEventListener('online', log);
    return () => {
      window.removeEventListener('offline', log);
      window.removeEventListener('online', log);
    };
  }, []);

  useEffect(() => {
    if (data) {
      setFellow(fellows.find((x) => x.id === data.fellowId));
    }
  }, [data]);

  useEffect(() => {
    if (size.width && fellow) {
      setWrapper(wrapperStyle(size.width, fellow));
      setAvatar(avatarStyle(size.width));
    }
  }, [fellow, size.width]);

  return (
    <>
      <div className="h-screen bg-white flex items-center justify-center">
        {loading && <LoadingSpinner />}
        {error && <p>{error?.message}</p>}
        {offline && (
          <div className="text-3xl text-gray-700 text-center font-nanum font-bold transform rotate-90">
            네트워크 연결이 끊어졌습니다.
            <br />
            관리자에게 문의 바랍니다.
          </div>
        )}
        {data && !offline && (
          <div
            className="flex flex-col items-center justify-between transform rotate-90"
            style={wrapper}
          >
            <div className="font-nanum font-black">{data.user.name}님</div>
            <img
              src={data.user.avatar}
              alt={data.user.name}
              className="inline-block h-80 w-80 rounded-full"
              style={avatar}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Welcome;
