import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { auth } from '../lib/firebase';

const Signout = () => {
  useEffect(() => {
    async function signOut() {
      await auth.signOut();
    }

    signOut();
  }, []);

  return <Redirect to={'/'} />;
};

export default Signout;
