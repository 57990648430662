import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
