export const resizeImage = (
  base64Str: string,
  maxSize: number = 640,
  minSize: number = 320
) => {
  return new Promise<string>((resolve, reject) => {
    const image = new Image();
    image.src = base64Str;
    let width = image.width;
    let height = image.height;

    image.onload = () => {
      const canvas = document.createElement('canvas');

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);

      resolve(canvas.toDataURL('image/jpeg'));
    };
  });
};
