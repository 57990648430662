import { createContext } from 'react';
import firebase from 'firebase/app';
import { UserInfo } from '../types';

export type UserValue = {
  user: firebase.User | null | undefined;
  info: UserInfo | null;
};

export const UserContext = createContext<UserValue>({
  user: null,
  info: null,
});
