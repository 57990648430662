import React, { FunctionComponent, useState } from 'react';
import { NavLink } from 'react-router-dom';
// import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { classNames } from '../../utils';

type Props = {
  fullWidth?: boolean;
  children: React.ReactNode;
};

const Layout: FunctionComponent<Props> = ({ fullWidth, children }) => {
  const [hideOnScroll] = useState(true);

  const mainClass = fullWidth ? '' : 'max-w-xl md:mx-auto py-12';

  // useScrollPosition(
  //   ({ prevPos, currPos }) => {
  //     let isShow = currPos.y > prevPos.y;
  //     isShow = currPos.y >= 0 || isShow;
  //     if (isShow !== hideOnScroll) setHideOnScroll(isShow);
  //   },
  //   [hideOnScroll],
  //   undefined,
  //   false,
  //   200
  // );

  return (
    <>
      <div
        className={classNames(
          hideOnScroll ? 'navbar-show' : 'navbar-hide',
          'z-10 fixed w-full bg-purple shadow-md'
        )}
      >
        <div className="max-w-xl mx-auto">
          <div className="flex items-center justify-around h-12">
            <NavLink exact to={'/'} className="navlink">
              전시소개
            </NavLink>
            <NavLink to={'/me'} className="navlink">
              나
            </NavLink>
            <NavLink to={'/friends'} className="navlink">
              친구들
            </NavLink>
          </div>
        </div>
      </div>
      <main className={mainClass}>{children}</main>
    </>
  );
};

export default Layout;
