import { RouteComponentProps } from 'react-router-dom';
import Layout from '../components/layouts/public';
import ForceGraph from '../components/ForceGraph';

interface MatchParams {
  id?: string;
}

const Circle = ({ match }: RouteComponentProps<MatchParams>) => {
  const { id } = match.params;

  return (
    <Layout fullWidth>
      <ForceGraph uid={id} />
    </Layout>
  );
};

export default Circle;
